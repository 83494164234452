import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  exportLastWeek() {
    let endDate = new Date();
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()-7);
    this.modalController.dismiss({startDate, endDate});
  }

  exportLastMonth() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    this.modalController.dismiss({startDate, endDate});
  }

  exportThisMonth() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.modalController.dismiss({startDate, endDate});
  }

  exportCustomRange(start: string, end: string) {
    const sd = new Date(start);
    const startDate = new Date(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(end);
    const endDate = new Date(ed.getFullYear(), ed.getMonth(), ed.getDate());
    this.modalController.dismiss({startDate, endDate});
  }
}
