import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './data-access/authentication.service';
import {MenuController, ModalController} from '@ionic/angular';
import {TrailwerkFacade} from './data-access/trailwerk.facade';
import {ExportModalComponent} from "./dashboard/export-modal/export-modal.component";
import {take, withLatestFrom} from "rxjs/operators";
import {Run, Trail} from "./data-access/trailwerk.model";
import {saveAs} from 'file-saver';
import {Observable} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public trails$: Observable<Trail[]> = this.trailwerkFacade.trails$;

  constructor(public trailwerkFacade: TrailwerkFacade, public authService: AuthenticationService, private menu: MenuController, public modalController: ModalController) {
  }

  ngOnInit() {
  }

  onSignOut() {
    this.authService.signOut().then(() => {
      this.menu.close();
    });

  }

  public canViewAdminButton() {
    return this.trailwerkFacade.isAdmin();
  }

  public cloneToDev($event: MouseEvent) {
    this.trailwerkFacade.cloneToDev();
  }

  public parseRuns($event: MouseEvent) {
    this.trailwerkFacade.parseRuns();
  }

  public canViewExportButton() {
    return this.trailwerkFacade.canExport();
  }

  public async openExportModal($event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    const modal = await this.modalController.create({
      component: ExportModalComponent,
      cssClass: 'my-custom-class'
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();

    if (data.startDate || data.endDate) {
      this.trailwerkFacade.getTrailRunsForDateRange(data.startDate, data.endDate)
        .pipe(
          take(1),
          withLatestFrom(this.trails$)
        )
        .subscribe(([runs, trails]) => this.downloadFile(trails, runs, data.startDate, data.endDate));
    }
  }

  private downloadFile(trails: Trail[], runs: Run[], startDate: Date, endDate: Date) {
    runs = runs.map((run: Run) => ({...run, date: new Date(run.date.seconds * 1000)}));

    const header = ['date', ...trails.sort((a, b) => a.id - b.id).map((t: Trail) => t.id)];

    const csv: string[] = [];
    csv.push(header.join(','));

    for (const d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateCsv: string[] = [this.dateToString(d)];
      for (const trailId in header) {
        if (trailId === 'date') {
          continue;
        }
        const run: Run = runs.find((el: Run) => {
          if (el.trail.toString() === trailId && this.isSameDate(el.date, d)) {
            return true;
          }
        });
        if (run) {
          dateCsv.push('x');
        } else {
          dateCsv.push('-');
        }
      }
      csv.push(dateCsv.join(','));
    }
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], {type: 'text/csv'});
    console.log(startDate, endDate);
    saveAs(blob, `trailwerk_${this.dateToString(startDate)}_${this.dateToString(endDate)}.csv`);
  }

  private isSameDate(startDate: Date, endDate: Date): boolean {
    return startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getDate() === endDate.getDate();
  }

  private dateToString(date: Date): string {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }



}
