import {Injectable, NgZone} from '@angular/core';
import firebase from 'firebase';
import {User} from './user.model';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  public userData: User;

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {
    this.ngFireAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  async signIn(email: string, password: string) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  async registerUser(displayName: string, email: string, password: string): Promise<firebase.auth.UserCredential> {
    try {
      const newUserCredential: firebase.auth.UserCredential = await this.ngFireAuth.createUserWithEmailAndPassword(email, password);
      await newUserCredential.user.updateProfile({displayName});
      await newUserCredential.user.sendEmailVerification();
      return newUserCredential;
    } catch (error) {
      throw error;
    }
  }

  // Recover password
  passwordRecover(email: string) {
    return this.ngFireAuth.sendPasswordResetEmail(email)
      .then(() => {})
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = this.userData;
    return (user !== null && user.emailVerified !== false);
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = this.userData;
    if (user === null || user === undefined) return false;
    console.log("isEmailVerified", user);
    return user.emailVerified;
  }

  async sendVerificationMail() {
    try {
      this.ngFireAuth.currentUser.then(u => u.sendEmailVerification());
    } catch (error) {
      throw error;
    }
  }

  // Sign in with Gmail
  googleAuth() {
    return this.authLogin(new GoogleAuthProvider());
  }

  // Auth providers
  authLogin(provider) {
    return this.ngFireAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.setUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Store user in localStorage
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign-out
  signOut() {
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }

  getWhitelist() {
    return this.afStore.collection<string[]>('config').doc('whitelist').valueChanges();
  }
}
