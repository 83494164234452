import {Run, Trail} from './trailwerk.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TrailwerkService} from './trailwerk.service';
import {AuthenticationService} from './authentication.service';
import {DocumentReference} from '@angular/fire/firestore';
import {ToastController} from '@ionic/angular';
import {User} from "./user.model";
import {tap} from "rxjs/operators";


@Injectable()
export class TrailwerkFacade {

  public trails$: Observable<Trail[]> = this.trailwerkService.getTrails();

  private readonly initialData: Trail[] = [
    {id: 0, trailNumber: '1', name: 'Stairway to Heaven', color: '#c0bfbf'},
    {id: 1, trailNumber: 'A1', name: 'Feuerwehrweg', color: '#d52d3d'},
    {id: 2, trailNumber: 'A2', name: 'Potschnweg', color: '#656772'},
    {id: 17, trailNumber: 'A3', name: 'Superiore', color: '#b58336'},
    {id: 4, trailNumber: 'A4', name: 'Schickhnway', color: '#0f4c29'},
    // {id: 3, name: 'Plan B', color: '#135989'},
    {id: 8, trailNumber: 'B1', name: 'Pan', color: '#115989'},
    {id: 15, trailNumber: 'B2', name: 'Pandemie', color: '#8b70b2'},
    {id: 6, trailNumber: 'C1', name: 'Uno', color: '#3cbfbd'},
    {id: 7, trailNumber: 'C2', name: 'Roger', color: '#f68b62'},
    {id: 11, trailNumber: 'C3', name: 'Ragazzi', color: '#ee2c71'},
    {id: 5, trailNumber: 'C4', name: 'AndiU', color: '#6d5633'},
    {id: 14, trailNumber: 'C5', name: 'Fitnessteller', color: '#e9e736'},
    {id: 16, trailNumber: 'C6', name: 'Brettljause', color: '#000000'},
    // {id: 9, name: 'Pandora', color: '#8a70b2'},
    // {id: 10, name: 'Anno1083', color: '#656872'},
    // {id: 12, name: 'Anno2019', color: '#656872'},
    // {id: 13, name: 'Warteschleife', color: '#656872'},

  ];

  constructor(public authService: AuthenticationService,
              public trailwerkService: TrailwerkService,
              public toastController: ToastController) {
    // this.createTrails();
  }

  public whitelist() {
    return this.authService.getWhitelist();
  }

  public user(): User {
    return this.authService.userData;
  }

  public createTrails(): void {
    this.initialData.forEach((trail: Trail) => {
      this.trailwerkService.createTrail(trail);
    });
  }

  public getRunsByTrailId(trailId: number, limit?: number): Observable<Run[]> {
    return this.trailwerkService.getRunsByTrailId(trailId, limit);
  }

  public getTrailRunsForDateRange(startDate: Date, endDate: Date): Observable<Run[]> {
    return this.trailwerkService.getRunsForDateRange(startDate, endDate);
  }

  public addRun(trail: Trail): void {
    this.trailwerkService.addRun(trail).then((runRef: DocumentReference<Run>) => {
      runRef.get().then((d) => {
        const run: Run = d.data() as Run;

        const latestRunAt: string = run.date;
        const latestRunBy: string = run.user;

        const runsPerUser: any = {...trail.runsPerUser};
        if (runsPerUser && run.user in runsPerUser) {
          runsPerUser[run.user] = runsPerUser[run.user] + 1;
        } else {
          runsPerUser[run.user] = 1;
        }

        this.trailwerkService.updateTrail({...trail, latestRunAt, latestRunBy, runsPerUser});
      });
      this.presentToast(trail, runRef);
    });
  }

  public cloneToDev() {
    this.trailwerkService.cloneToDev();
  }

  async presentToast(trail: Trail, runRef: DocumentReference<Run>) {
    const toast = await this.toastController.create({
      message: 'Fahrt wurde gespeichert!',
      position: 'bottom',
      cssClass: 'trailwerk-snackbar',
      duration: 10000,
      buttons: [
        {
          side: 'end',
          text: 'Rückgangig',
          handler: () => {
            runRef.delete();
            this.trailwerkService.updateTrail(trail);
          }
        }
      ]
    });
    toast.present();
  }


  public isAdmin(): boolean {
    const validUserId: string[] = [
      '9A5C78Y5gbOPKD3Yd9Pes5FamII3',  // stefan.martinek
    ];
    if (!this.authService || !this.authService.userData) {
      return false;
    }
    return validUserId.includes(this.authService.userData.uid);
  }

  public canExport(): boolean {
    const validUserId: string[] = [
      'ckep4pBcGOZrF9QSa8wLMkUN1572', // gottfried.schabasser
      '9A5C78Y5gbOPKD3Yd9Pes5FamII3',  // stefan.martinek
      'iBkEiZaAUEOPzhYItda0Ecp0dZM2'   // andi
    ];

    if (!this.authService || !this.authService.userData) {
      return false;
    }
    return validUserId.includes(this.authService.userData.uid);
  }

  public parseRuns() {
    this.trailwerkService.parseRuns(this.initialData);
  }
}
