import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference, QueryFn} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {debounceTime, map, take, tap} from 'rxjs/operators';
import {Run, Trail} from './trailwerk.model';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrailwerkService {

  private readonly trailColumn: string;
  private readonly runColumn: string;

  constructor(private authService: AuthenticationService, private afs: AngularFirestore) {
    console.log('using db postfix ' + environment.dbPrefix);
    this.trailColumn = `trails${environment.dbPrefix}`;
    this.runColumn = `runs${environment.dbPrefix}`;
  }

  public createTrail(trail: Trail): Promise<void> {
    return this.afs.collection(this.trailColumn)
      .doc<Trail>(trail.name)
      .set({...trail, latestRunAt: null, latestRunBy: null});
  }

  public getTrails(): Observable<Trail[]> {
    return this.afs
      .collection<Trail>(this.trailColumn, ref => ref.orderBy('latestRunAt', 'asc'))
      .valueChanges()
      .pipe(
        debounceTime(250)
      );
  }

  public getRunsByTrailId(trailId: number, limit?: number): Observable<Run[]> {
    let queryFn: QueryFn;
    if (limit) {
      queryFn = (ref) => ref.where('trail', '==', trailId).orderBy('date', 'desc').limit(limit);
    } else {
      queryFn = (ref) => ref.where('trail', '==', trailId).orderBy('date', 'desc');
    }
    return this.afs
      .collection<Run>(this.runColumn, queryFn)
      .valueChanges()
      .pipe(
        take(1)
      );
  }

  public getRunsForDateRange(startDate: Date, endDate: Date): Observable<Run[]> {
    const queryFn = (ref) => ref
      .where('date', '>=', new Date(startDate))
      .where('date', '<', new Date(endDate));
    return this.afs
      .collection<Run>(this.runColumn, queryFn)
      .valueChanges().pipe(take(1));
  }

  public addRun(trail: Trail): Promise<DocumentReference<Run>> {
    const runAt: any = new Date();
    const runBy: any = this.authService.userData.displayName;

    return this.afs.collection<Run>(this.runColumn).add({
      trail: trail.id,
      user: runBy,
      date: runAt
    });
  }

  public updateTrail(trail: Trail) {
    this.afs.collection(this.trailColumn).doc<Trail>(trail.name).set(trail, {merge: true});
  }

  public cloneToDev() {
    this.afs.collection<Run>('runs_dev').valueChanges({idField: 'docId'}).pipe(
      take(1),
      tap((runs: Run[]) => runs.forEach((run: Run) => {
        this.afs.collection<Run>('runs_dev').doc(run.docId).delete();
      })),
      tap(() => console.log('runs_dev deleted'))
    ).subscribe();
    return this.afs
      .collection<Run>('runs').valueChanges().pipe(
        take(1),
        tap((runs: Run[]) => {
            runs.forEach((run: Run) => {
              this.afs.collection<Run>('runs_dev').add(run);
            });
          }
        ),
        tap(() => console.log('runs imported'))).subscribe();
  }

  public parseRuns(trails: Trail[]) {
    const trailMap: any = {};
    trails.forEach((trail: Trail) => {
      trailMap[trail.id] = trail;
    });
    this.afs.collection<Run>(this.runColumn).valueChanges({idField: 'docId'}).pipe(
      take(1),
      map((runs: Run[]) => {
        runs.forEach((run: Run) => {
          const trail: Trail = trailMap[run.trail];
          if (trail) {
            const runsPerUser: any = {...trail.runsPerUser};
            if (runsPerUser && run.user in runsPerUser) {
              runsPerUser[run.user] = runsPerUser[run.user] + 1;
            } else {
              runsPerUser[run.user] = 1;
            }

            if (!trail.latestRunAt || run.date > trail.latestRunAt) {
              trail.latestRunAt = run.date;
              trail.latestRunBy = run.user;
            }

            trail.runsPerUser = runsPerUser;
          }
        })
        return Object.values(trailMap);
      })
    )
      .subscribe((updatedTrails: Trail[]) => {
        console.log(updatedTrails);
        updatedTrails.forEach((trail: Trail) => {
          return this.afs.collection(this.trailColumn)
            .doc<Trail>(trail.name)
            .update(trail);
        });
        console.log('runs parsed!')
      });
  }
}
